<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div v-show="!processDisabled" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 단위공정 -->
          <c-process
            :editable="editable"
            :disabled="processDisabled"
            label="LBL0001701"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
      </template>
    </c-search-box>
    <!-- 작업/작업단계/유해위험요인 목록 -->
    <c-table
      ref="table"
      title="LBL0001708"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :isExcelDown="false"
      :isExpan="false"
      :isFullScreen="false"
      :columnSetting="false"
      rowKey="idx"
      @linkClick="openPop"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          // 1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 
          // 4:작업단계별 유해위험요인별 원인/결과, 5. 공정별 유해위험요인, 
          // 6. 단위공정별 유해위험요인 원인 / 결과
          // 7. 단위공정별 작업별 유해위험요인
          searchType: '1', 
          subProcessCd: '',
          jobStepId: '',
          mdmHazardFactorsId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        subProcessCd: '',
        sopName: '',
        jobStepName: '',
        useFlag: 'Y',
        jobStepId: '',
        mdmHazardFactorsId: '',
        searchType: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'deptName' },
          { index: 1, colName: 'lineName' },
          { index: 2, colName: 'processName' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'lineName',
            field: 'lineName',
            label: '라인',
            style: 'width: 100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: false,
            style: 'width: 150px',
            type: 'link'
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업순서',
            align: 'left',
            sortable: false,
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            label: '분류/유해위험요인',
            align: 'left',
            style: 'width: 150px',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '상황/원인',
            align: 'left',
            style: 'width: 200px',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            type: 'html',
            sortable: false,
          },
        ],
        height: '650px',
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    processDisabled() {
      return this.popupParam.subProcessCd ? this.popupParam.subProcessCd.indexOf('WTE') === -1 : false;
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.sop.jobhazardreason.url;
      this.searchParam.searchType = this.popupParam.searchType;
      if (this.popupParam.searchType == '2') { // 작업단계
        this.grid.merge =  [
          { index: 0, colName: 'deptName' },
          { index: 1, colName: 'lineName' },
          { index: 2, colName: 'processName' },
        ],
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false
          },
          {
            name: 'lineName',
            field: 'lineName',
            label: '라인',
            style: 'width: 180px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: false,
            style: 'width: 180px',
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업순서',
            align: 'left',
            sortable: false,
          },
        ]
      } else {
        this.grid.merge =  [
          { index: 0, colName: 'deptName' },
          { index: 1, colName: 'lineName' },
          { index: 2, colName: 'processName' },
        ],
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false
          },
          {
            name: 'lineName',
            field: 'lineName',
            label: '라인',
            style: 'width: 180px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: false,
            style: 'width: 180px',
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업순서',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            label: '분류/유해위험요인',
            align: 'left',
            style: 'width: 150px',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '상황/원인',
            align: 'left',
            style: 'width: 200px',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            type: 'html',
            sortable: false,
          },
        ]
      }

      if (this.popupParam.subProcessCd) {
        if (this.popupParam.subProcessCd.indexOf('WTE') === -1) {
          this.searchParam.subProcessCd = this.popupParam.subProcessCd
        } else {
          this.searchParam.subProcessCd = null;
        }
      }
      if (this.popupParam.jobStepId) {
        this.searchParam.jobStepId = this.popupParam.jobStepId
      }
      if (this.popupParam.mdmHazardFactorsId) {
        this.searchParam.mdmHazardFactorsId = this.popupParam.mdmHazardFactorsId
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    openPop(row) {
      this.popupOptions.title = '안전작업표준 상세';
      this.popupOptions.param = {
        mdmSopId: row ? row.mdmSopId : '',
        tabpage: 'sopReport'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
